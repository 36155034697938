import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { TranslateConfigService } from '../../translate-config.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    public isCollapsed = true;

    currentActiveTab: any = "dashboard";
    ownerSettings: any = 0;
    currentOwner: any = "";

    dashboardDisplay: any = 1;
    adminDisplay: any = 0;
    reportsDisplay: any = 0;
    searchDisplay: any = 0;
    commandsDisplay: any = 0;
    platformLanguage: any = 0;

    settings = [
      {"name": "vsReports","value": '1',"enabled": 0, "label": "View Reports"},
      {"name": "vsTools","value": "2","enabled": 0, "label": "View Tools"},
      {"name": "vsAdmin","value": "4","enabled": 0, "label": "View Admin"},
      {"name": "vsCreateOwner","value": "8","enabled": 0, "label": "Create Owner"},
      {"name": "vsChangeExpiry","value": "16","enabled": 0, "label": "Edit Subscription"},
      {"name": "vsAddDevice","value": "32","enabled": 0, "label": "Create Device"},
      {"name": "vsEditDevice","value": "64","enabled": 0, "label": "Edit Device"},
      {"name": "vsMoveDevice","value": "128","enabled": 0, "label": "Move Device"},
      {"name": "vsSearch","value": "256","enabled": 0, "label": "View Search"},
      {"name": "vsCommands","value": "512","enabled": 0, "label": "View Commands"},
      {"name": "vsShowEco","value": "1024","enabled": 0, "label": "Edit Eco Mode"},
      {"name": "vsShowPark","value": "2048","enabled": 0, "label": "Edit Park Mode"},
      {"name": "vsShowFlight","value": "4096","enabled": 0, "label": "Edit Flight Mode"},
      {"name": "vsShowFlightLog","value": "8192","enabled": 0, "label": "Edit Log Mode"},
      {"name": "vsShowPursuit","value": "16384","enabled": 0, "label": "Edit Pursuit"},
      {"name": "vsGeneralSettings","value": "32768","enabled": 0, "label": "View General Settings"},
      {"name": "vsAdminPassword","value": "65536","enabled": 0, "label": "View Admin Password"},
      {"name": "vsActivationOnly","value": "131072","enabled": 0, "label": ""},
      {"name": "vsM2MTab","value": "262144","enabled": 0, "label": "Show M2M tab"},
      {"name": "vsCreateDeleteAccount","value": "524288","enabled": 0, "label": "Create Account"},
      {"name": "vsSupportTab","value": "1048576","enabled": 0, "label": "Show Support tab"},
      {"name": "vsStatusTab","value": "2097152","enabled": 0, "label": "Show Status tab"},
      {"name": "vsAccountSettings","value": "4194304","enabled": 0, "label": "Show Account settings"},
      {"name": "vsDeleteHistory","value": "8388608","enabled": 0, "label": "Show Delete History"},
      {"name": "vsThatchamTab","value": "16777216","enabled": 0, "label": ""},
      {"name": "vsDashboard","value": "-1","enabled": 1, "label": "Dashboard"},
      {"name": "vsSubscription","value": "-2","enabled": 0, "label": "Subscriptions"}
    ]

    @ViewChild("navbar-cmp", {static: false}) button;

    constructor(location:Location, private renderer : Renderer2, private element : ElementRef, private router: Router, private languageService: TranslateConfigService, private translateService: TranslateService) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    ngOnInit(){
       this.listTitles = ROUTES.filter(listTitle => listTitle);
       console.log(this.listTitles);
       var navbar : HTMLElement = this.element.nativeElement;
       this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
       this.router.events.subscribe((event) => {
         this.sidebarClose();
      });
      this.ownerSettings = parseInt(localStorage.getItem('OwnerSettings'));
      this.platformLanguage = parseInt(localStorage.getItem('language'));
      this.currentOwner = localStorage.getItem('Name');

      if(this.platformLanguage == 1){
        this.languageService.setLanguage("fr");
      }
      else{
        this.languageService.setLanguage("en");
      }

      let ownerSettings = (this.dec2bin(this.ownerSettings)).reverse();
      for (let i = 0; i < ownerSettings.length; i++) {
        this.settings[i]['enabled'] = parseInt(ownerSettings[i]);
      }

      if(this.currentOwner == "DGGN"){
        this.settings[26]['enabled'] = 1;
      }


      this.currentActiveTab = this.location["_platformLocation"].location.pathname.replace("/", "");
      console.log(this.currentActiveTab);
    }

    toggleActive(currentActive){
      this.currentActiveTab = currentActive;
    }

    logout(){
      localStorage.removeItem('LoginID');
      localStorage.removeItem('LoginToken');
      localStorage.removeItem('OwnerSettings');
      this.router.navigate(['/']);
    }

    dec2bin(n) {
      return n.toString(2).split('');
    }

    changeLang(){
      localStorage.setItem("language", this.platformLanguage);
      if(this.platformLanguage == 1){
        this.languageService.setLanguage("fr");
      }
      else{
        this.languageService.setLanguage("en");
      }
    }

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
      }
      sidebarOpen() {
          const toggleButton = this.toggleButton;
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          setTimeout(function(){
              toggleButton.classList.add('toggled');
          }, 500);

          html.classList.add('nav-open');
          if (window.innerWidth < 991) {
            mainPanel.style.position = 'fixed';
          }
          this.sidebarVisible = true;
      };
      sidebarClose() {
          const html = document.getElementsByTagName('html')[0];
          const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
          if (window.innerWidth < 991) {
            setTimeout(function(){
              mainPanel.style.position = '';
            }, 500);
          }
          this.toggleButton.classList.remove('toggled');
          this.sidebarVisible = false;
          html.classList.remove('nav-open');
      };
      collapse(){
        this.isCollapsed = !this.isCollapsed;
        const navbar = document.getElementsByTagName('nav')[0];
        console.log(navbar);
        if (!this.isCollapsed) {
          navbar.classList.remove('navbar-transparent');
          navbar.classList.add('bg-white');
        }else{
          navbar.classList.add('navbar-transparent');
          navbar.classList.remove('bg-white');
        }

      }

}
