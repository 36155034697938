import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const LNG_KEY = 'SELECTED_LANGUAGE';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  selected = '';

  constructor(private translate: TranslateService) { }

  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    if(language == "fr" || "en"){
      this.translate.setDefaultLang(language);
    }
    else{
      this.translate.setDefaultLang("en");
    }
  }
 
  setLanguage(lng) {
    this.translate.use(lng);
    this.selected = lng;
    localStorage.setItem(LNG_KEY, lng);
  }
}
