import { Component, OnInit } from '@angular/core';


export interface RouteInfo {
    path: string;
    title: string;
    frTitle: string;
    icon: string;
    class: string;
    integer: any;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',     frTitle: 'Tableau de Bord',   icon:'nc-bank',       class: 'dashboard',       integer: 25 },
    { path: '/admin',         title: 'Admin',         frTitle: 'Admin',             icon:'nc-diamond',    class: 'admin',           integer: 2 },
    { path: '/reports',       title: 'Reports',       frTitle: 'Rapports',          icon:'nc-pin-3',      class: 'reports',         integer: 0 },
    { path: '/search',        title: 'Search',        frTitle: 'Recherche',         icon:'nc-bell-55',    class: 'search',          integer: 8 },
    { path: '/commands',      title: 'Commands',      frTitle: 'Commandes',         icon:'nc-single-02',  class: 'commands',        integer: 9 },
    { path: '/subscriptions', title: 'Subscriptions', frTitle: 'Abonnements',       icon:'nc-single-02',  class: 'subscriptions',   integer: 26 },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
